import { CustomProvider, Navbar, Nav } from 'rsuite';
import './App.css';

function App() {
  return (
    <CustomProvider theme="dark">
        <Navbar>
          <Nav>
            <Nav.Item disabled>Neon Void Services</Nav.Item>
          </Nav>
        </Navbar>

    <div className="App">
      <header className="App-header">
        <p>
          <a className="twitchLogin" href="/api/auth/twitch">Login with Twitch</a>
        </p>
      </header>
    </div>
    </CustomProvider>
  );
}

export default App;
