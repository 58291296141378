import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  CustomProvider,
  Navbar,
  Nav,
  Panel,
  Toggle,
  Loader,
  SelectPicker,
  Divider,
} from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import "rsuite/dist/rsuite.min.css";
import "./Dashboard.css";

function Dashboard() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBotJoined, setBotJoined] = useState(false);
  const [items, setItems] = useState([]);
  const [botPersonalities, setBotPersonalities] = useState([]);
  const [currentBotPersonality, setCurrentBotPersonality] = useState(null);
  const [currentBotAccessLevel, setCurrentBotAccessLevel] =
    useState("everyone");

  useEffect(() => {
    fetch("/api/account/active")
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          const channel = result.login;
          fetch(`/api/bot/has/${channel}`)
            .then((res) => res.json())
            .then((result) => {
              setBotJoined(result.joined);
              fetch(`/api/bot/personalities`)
                .then((res) => res.json())
                .then((result) => {
                  const selectPickerBotPersonalities =
                    result.botPersonalities.map((personality) => {
                      return {
                        label: `${personality.botPersonalityName}`,
                        value: personality.id,
                      };
                    });
                  setBotPersonalities(selectPickerBotPersonalities);
                  setCurrentBotPersonality(result.currentBotPersonality);

                  fetch(`/api/bot/accesslevel`)
                    .then((res) => res.json())
                    .then((result) => {
                      setCurrentBotAccessLevel(result.botAccessLevel);
                      console.log(result);
                      setIsLoaded(true);
                    });
                });
            });
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      );
  }, []);

  if (error) {
    return <Navigate replace to="/" />;
  } else if (!isLoaded) {
    return <Loader backdrop size="lg" />;
  } else if (!items.active) {
    return <Navigate replace to="/" />;
  }

  const switchBot = (state) => {
    if (state === false) {
      fetch(`/api/bot/leave/${items.login}`)
        .then((res) => res.json())
        .then((result) => {
          // bot disabled
        });
    } else {
      fetch(`/api/bot/join/${items.login}`)
        .then((res) => res.json())
        .then((result) => {
          // bot disabled
        });
    }
  };

  const switchPersonality = (personalityId) => {
    fetch(`/api/bot/personality/${items.login}/${personalityId}`)
      .then((res) => res.json())
      .then((result) => {
        // bot personality set
      });
  };

  const switchBotAccessLevel = (botAccessLevel) => {
    fetch(`/api/bot/accesslevel/${items.login}/${botAccessLevel}`)
      .then((res) => res.json())
      .then((result) => {
        // bot access level set
      });
  };

  return (
    <CustomProvider theme="dark">
      <div className="Dashboard">
        <Navbar>
          <Nav>
            <Nav.Item disabled>Neon Void Services</Nav.Item>
          </Nav>
          <Nav pullRight>
            <Nav.Item href="/api/logout">Logout</Nav.Item>
          </Nav>
        </Navbar>
        <div className="panelHolder">
          <Panel
            header="Chat Bot Settings"
            bordered
            style={{ display: "inline-block", minWidth: 320 }}
          >
            <div>
              <span className="formLabel">Bot Name:</span> <b>hal_8580</b>
            </div>
            <br />
            <div>
              <span className="formLabel">Channel:</span> <b>#{items.login}</b>
            </div>
            <br />
            <div>
              <span className="formLabel">Bot Enabled:</span>
              <Toggle
                checkedChildren={<CheckIcon />}
                unCheckedChildren={<CloseIcon />}
                defaultChecked={isBotJoined}
                onChange={switchBot}
              />
            </div>
            <br />
            <div>
              <span className="formLabel">Access Level:</span>
              <SelectPicker
                data={[
                  {
                    label: "Everyone",
                    value: "everyone",
                  },
                  { label: "Subcribers", value: "subscribers" },
                  { label: "Mods", value: "mods" },
                ]}
                searchable={false}
                cleanable={false}
                onChange={switchBotAccessLevel}
                defaultValue={currentBotAccessLevel}
                style={{ marginBottom: 8 }}
              />
            </div>
            <div>
              <span className="formLabel">Personality:</span>
              <SelectPicker
                data={botPersonalities}
                searchable={false}
                cleanable={false}
                onChange={switchPersonality}
                defaultValue={currentBotPersonality}
              />
            </div>
            <Divider />
            <div>
              <p>
                <h4>quick start</h4>
                <div className="halText">
                  <ul>
                    <li>
                      <b>hal_8580</b> is an experimental twitch chatbot based on
                      openai's chatgpt 3.5 engine. It can maintain a
                      conversation & provide endless entertainment for your
                      viewers.
                    </li>
                    <li>
                      <b>hal_8580</b> won't talk, unless explicitly addressed;
                      either mentioned as <b>@hal_8580</b> or through the{" "}
                      <b>!hal</b> command.
                    </li>
                    <li>
                      You can set up who will <b>hal_8580</b> reply: to everyone, to subscribers, or to mods only.
                    </li>
                    <li>
                      To add <b>hal_8580</b> to your channel, simply enable the
                      bot above. To remove, simple click again to disable.
                    </li>
                  </ul>
                </div>
              </p>
              <p>
                <h4>current features</h4>
                <div className="halText">
                  <ul>
                    <li>
                      <b>@hal_8580</b> can remember each conversation threads
                      with every individuals for up to 60 minutes
                    </li>
                    <li>
                      There are multiple personalities to pick from:{" "}
                      <b>HAL 8580</b> is polite & helpful, <b>HAL DARK</b> is a
                      sarcastic one with dark humor.
                    </li>
                  </ul>
                </div>
              </p>
              <p>
                <h4>current limitations</h4>
                <div className="halText">
                  <ul>
                    <li>
                      <b>@hal_8580</b>'s name cannot be changed.
                    </li>
                    <li>
                      <b>@hal_8580</b> can only join to one channel per account;
                      to your own channel.
                    </li>
                    <li>
                      <b>@hal_8580</b> cannot be tasked to schedule certain
                      tasks (for an example do X in every 15 minutes)
                    </li>
                  </ul>
                </div>
              </p>
              <p>
                <h4>troubleshooting</h4>
                <div className="halText">
                  <ul>
                    <li>
                      If <b>@hal_8580</b> is not responding on your channel...
                    </li>
                    <li>
                      1. Check your auto-mod settings, make sure its not filtering out <b>@hal_8580</b> by default
                    </li>
                    <li>
                      2. Check if there is any other bot on your channel which might automatically censors <b>@hal_8580</b>
                    </li>
                    <li>
                      3. As a last resort, you can try to <code>/mod @hal_8580</code>
                    </li>
                  </ul>
                </div>
              </p>
              <p>
                <h4>support</h4>
                <div className="halText">
                  <ul>
                    <li>
                      Please send your questions & feedback to <b>hal_8580</b>'s{" "}
                      <a href="https://discord.gg/JP8XC8FWfW">
                        discord channel
                      </a>
                      .
                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </Panel>
        </div>
      </div>
    </CustomProvider>
  );
}

export default Dashboard;
